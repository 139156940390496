import { navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import { startCase } from "lodash"
import { REVIEWER_ABSTRACT_DETAIL, LOGIN } from "../../constants/routes"
import { LOGOUT, VIEW_ABSTRACT } from "../../redux/actionTypes"
import { Files } from "../../redux/api"
import { getBadgeStyling, renameStatus } from "../../utils/styling"
import Avatar from "../images/avatar.jpg"
import { Table } from "antd"

const ReviewerDashboardContent = ({ state }) => {
  const dispatch = useDispatch()
  const [listFile, setListFile] = useState([])
  const profile = state.user.profile || {}
  const listColumn = [
    { title: "No", dataIndex: "id" },
    { title: "Title", dataIndex: "title" },
    { title: "Category", dataIndex: "category", render: c => startCase(c) },
    {
      title: "Status",
      dataIndex: "status",
      render: s => (
        <span className={getBadgeStyling(s)}>{renameStatus(s)}</span>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      render: id => (
        <button
          className="button button-3d button-circle gradient-grey-orange mr-0 abs-btn"
          value={id}
          onClick={viewAbstractDetail}
        >
          View
        </button>
      ),
    },
  ]

  const viewAbstractDetail = e => {
    const payload = listFile.filter(f => f.id === parseInt(e.target.value))[0]
    dispatch({ type: VIEW_ABSTRACT, payload })
    navigate(REVIEWER_ABSTRACT_DETAIL)
  }

  const fetchData = async () => {
    const rFiles = await Files.details()
    setListFile(rFiles ? (await rFiles.json()).files : [])
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="content-wrap">
      <div className="container clearfix">
        <div className="col-md-9 mt-4">
          <img
            src={Avatar}
            className="alignleft img-circle img-thumbnail my-0"
            alt="Avatar"
            style={{ maxWidth: "84px" }}
          />
          <div className="border-0 mb-0">
            <h3 className="profile-name mb-0">{profile.fullname}</h3>
            <h5>{profile.country}</h5>
          </div>
          <div className="clear" />
        </div>
        <div className="divider divider-center" />
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="clearfix" id="abstract">
              <div className="row mb-1">
                <div className="col-md-6">
                  <h3 className="float-left vertical-center mb-5">
                    Abstract Submission{" "}
                  </h3>
                </div>
              </div>
              <Table rowKey="id" dataSource={listFile} columns={listColumn} />
            </div>
          </div>
        </div>
        <div className="divider divider-center">
          <i className="icon-circle" />
        </div>
        <div className="col-12">
          <button
            className="button button-circle button-border button-white  float-right mr-0 text-center"
            onClick={() => {
              if (profile) dispatch({ type: LOGOUT })
              navigate(LOGIN)
            }}
          >
            Log Out
          </button>
          {/* Modal Log Out*/}
          <div className="modal1 mfp-hide" id="logout">
            <div
              className="block mx-auto"
              style={{ backgroundColor: "#FFF", maxWidth: "500px" }}
            >
              <div className="center" style={{ padding: "50px" }}>
                <h3>Log Out</h3>
                <p className="mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Nostrum delectus, tenetur obcaecati porro! Expedita nostrum
                  tempora quia provident perspiciatis inventore, autem eaque,
                  quod explicabo, ipsum, facilis aliquid! Sapiente, possimus
                  quo!
                </p>
              </div>
              <div className="section center m-0" style={{ padding: "30px" }}>
                <button
                  href="#"
                  className="button button-3d button-circle gradient-grey-orange mr-0"
                  style={{ padding: "10px 22px" }}
                >
                  Cancel
                </button>
                <button
                  href="index.html"
                  className="button button-circle button-border button-white"
                >
                  Log Out
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(state => ({ state }))(ReviewerDashboardContent)
