import React from "react"
import { startCase, lowerCase } from "lodash"
import { APPROVED } from "../constants/abstractStatus"

export const getBadgeStyling = s => {
  switch (s) {
    case "PENDING":
      return "badge badge-info"
    case "UNDER REVIEWED":
      return "badge badge-danger"
    case "REVISION NEEDED":
      return "badge badge-revision"
    case "APPROVED":
      return "badge badge-success"
    case "REJECTED":
      return "badge badge-rejected"
    default:
      break
  }
}

export const renameStatus = s => {
  if (!s) return ""
  if (s.toLowerCase() === APPROVED.toLowerCase()) return "Accepted"
  return startCase(lowerCase(s))
}

export const templatePdf = form => {
  const s = {
    container: {
      width: "400px",
    },
    title: {
      textAlign: "center",
      fontSize: "1rem",
    },
    subtitle: {
      textAlign: "center",
      fontSize: "0.5rem",
    },
    content: {
      fontSize: "0.5rem",
    },
  }
  return (
    <div style={s.container}>
      <p style={s.title}>{form.title}</p>
      <p style={s.subtitle}>
        {form.author}
        <br></br>
        {form.author_affiliation}
      </p>
      <br></br>
      <p style={s.content}>
        <b>Background</b>
        <br></br>
        {form.background}
      </p>
      <p style={s.content}>
        <b>Methods</b>
        <br></br>
        {form.methods}
      </p>
      <p style={s.content}>
        <b>Result</b>
        <br></br>
        {form.result}
      </p>
      <p style={s.content}>
        <b>Conclusion</b>
        <br></br>
        {form.conclusion}
      </p>
      <p style={s.content}>
        <b>Keywords</b>
        <br></br>
        {form.keywords.replaceAll(" ", ", ")}
      </p>
    </div>
  )
}
