import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { connect } from "react-redux"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Banner from "../components/banner"
import ReviewerDashboardContent from "../components/part/reviewerDashboardContent"
import { LOGIN } from "../constants/routes"

const ReviewerDashboardPage = ({ state }) => {
  useEffect(() => {
    const isLoggedIn = state.user.profile
    if (!isLoggedIn) return navigate(LOGIN)
  }, [state.user.profile])

  return (
    <Layout>
      <SEO title="Reviewer Dashboard" />
      <Banner title="Reviewer Dashboard"></Banner>
      <ReviewerDashboardContent></ReviewerDashboardContent>
    </Layout>
  )
}

export default connect(state => ({ state }))(ReviewerDashboardPage)
